<template>
  <!-- 预警大屏，预警中心  -->
  <div class="main m-warnscreen" id="warnCenter">
    <div class="head m-model">
      <div class="page-title">预警平台</div>
      <div class="flex spaceBetween" style="width: 100%; position: absolute; top: 0; left: 0">
        <div class="page-title-left">
          <div class="page-title-left-icon">
            <img v-lazy="$imgPrefix + logoUrl" alt="" style="height: 100%" />
          </div>
        </div>
        <div class="page-title-right flex">
          <!-- <div class="page-title-handle" @click="handleBtn"></div> -->
          <!-- <div class="colorBtn" @click="colorBtnClick"></div> -->
          <div>
            <ButtonChange :colorStatus="colorStatus" @changeValue="receiveValue" />
          </div>
          <div class="page-title-right-icon"></div>
          <div class="flex page-title-right-text info">
            <div class="address" style="margin-left: 2px; margin-right: 20px">
              {{ canteenName }}
            </div>
            <div class="date">
              {{ timer }}
            </div>
            <div class="week">{{ week }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="box left-block">
        <div class="head">
          <div class="icon-abc"></div>
          <div class="title parttitle">门禁预警</div>
        </div>
        <div class="content">
          <div class="table m-model">
            <div class="table-head">
              <div class="row text-weight-500">
                <div class="column column-first">序号</div>
                <div class="column column-second border-left">预警时间</div>
                <div class="column column-third border-left">人员姓名</div>
                <div class="column column-fourth border-left">异常类型</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="scroll-box">
              <div class="table-body">
                <div
                  class="row"
                  v-for="(item, index) in entranceAlarmList"
                  :key="index"
                  @click="openModal('entranceAlarmList', index)"
                >
                  <div class="column column-first text-weight-500">
                    {{ index + 1 }}
                  </div>
                  <div class="column column-second text-weight-500">
                    <div>{{ item.alarmTime.substring(0, 10) }}</div>
                    <div>{{ item.alarmTime.substring(11) }}</div>
                  </div>
                  <div class="column column-third text-weight-700">
                    {{ item.name }}
                  </div>
                  <div class="column column-fourth text-weight-700">
                    {{ item.type | typeFilter }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box center-block">
        <div class="head">
          <div class="icon-bca"></div>
          <div class="title parttitle">互联网+明厨亮灶</div>
        </div>
        <div class="content">
          <div class="table m-model">
            <div class="table-head">
              <div class="row text-weight-500 firstrow">
                <div class="column column-first">序号</div>
                <div class="column column-second border-left">预警时间</div>
                <div class="column column-third border-left">照片</div>
                <div class="column column-fourth border-left">异常类型</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="scroll-box">
              <div class="table-body">
                <div
                  class="row"
                  v-for="(item, index) in otherExcepList"
                  :key="index"
                  @click="openModal('otherExcepList', index)"
                >
                  <div class="column column-first text-weight-500">
                    {{ index + 1 }}
                  </div>
                  <div class="column column-second text-weight-500">
                    <div>{{ item.alarmTime.substring(0, 10) }}</div>
                    <div>{{ item.alarmTime.substring(11) }}</div>
                  </div>
                  <div class="column column-third text-weight-700">
                    <img v-lazy="item.pic" alt="" class="_img" />
                  </div>
                  <div class="column column-fourth text-weight-700">
                    {{ item.type | typeFilter }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box right-block">
        <div class="head">
          <div class="icon-cab"></div>
          <div class="title parttitle">留样预警</div>
        </div>
        <div class="content">
          <div class="first-level">
            <div class="table m-model">
              <div class="table-head">
                <div class="row text-weight-500">
                  <div class="column column-first">序号</div>
                  <div class="column column-second border-left">预警时间</div>
                  <div class="column column-third border-left">餐次</div>
                  <div class="column column-fourth border-left">异常类型</div>
                </div>
              </div>
              <div class="line"></div>
              <div class="scroll-box">
                <div class="table-body">
                  <div
                    class="row"
                    v-for="(item, index) in sampleAlarmList"
                    :key="index"
                    @click="openModal('sampleAlarmList', index)"
                  >
                    <div class="column column-first text-weight-500">
                      {{ index + 1 }}
                    </div>
                    <div class="column column-second text-weight-500">
                      <div>{{ item.alarmTime.substring(0, 10) }}</div>
                      <div>{{ item.alarmTime.substring(11) }}</div>
                    </div>
                    <div class="column column-third text-weight-700">
                      {{ item.name }}
                    </div>
                    <div class="column column-fourth text-weight-700">
                      {{ item.type | typeFilter }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="height: 20px"></div>

          <div class="head">
            <div class="icon-acb"></div>
            <div class="title parttitle">物联预警</div>
          </div>
          <div class="bottom-box">
            <!-- <div class="column-box">
              <div class="aaron-box">
                <div class="aaron-title">配餐间</div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div
                    :class="
                      echartDataList.device_1.isOnline ? 'light' : 'warning'
                    "
                  ></div>
                  <div>
                    {{ echartDataList.device_1.isOnline ? "在线" : "离线" }}
                  </div>
                </div>
              </div>
              <div class="echarts-box">
                <FsGauge :echartData="echartDataList.device_1.echartData_1" />
                <FsGauge :echartData="echartDataList.device_1.echartData_2" />
              </div>
            </div> -->
            <div class="column-box m-model">
              <div class="aaron-box">
                <div class="aaron-title">物联设备</div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div :class="echartDataList.device_2.isOnline ? 'light' : 'warning'"></div>
                  <div class="isOnline">
                    {{ echartDataList.device_2.isOnline ? '在线' : '离线' }}
                  </div>
                </div>
              </div>
              <div class="echarts-box">
                <FsGauge :echartData="echartDataList.device_2.echartData_1" />
                <FsGauge :echartData="echartDataList.device_2.echartData_2" />
              </div>
            </div>
            <!-- <div class="column-box">
              <div class="aaron-box">
                <div class="aaron-title">调味品仓库</div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div
                    :class="
                      echartDataList.device_3.isOnline ? 'light' : 'warning'
                    "
                  ></div>
                  <div>
                    {{ echartDataList.device_3.isOnline ? "在线" : "离线" }}
                  </div>
                </div>
              </div>
              <div class="echarts-box">
                <FsGauge :echartData="echartDataList.device_3.echartData_1" />
                <FsGauge :echartData="echartDataList.device_3.echartData_2" />
              </div>
            </div> -->
            <div class="column-box m-model">
              <div class="aaron-box">
                <div class="aaron-title">可燃气体</div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div :class="echartDataList.device_4.isOnline ? 'light' : 'warning'"></div>
                  <div class="isOnline">
                    {{ echartDataList.device_4.isOnline ? '在线' : '离线' }}
                  </div>
                </div>
              </div>
              <div class="echarts-box">
                <FsGauge :echartData="echartDataList.device_4.echartData_1" />
                <div class="valve-box">
                  <div v-if="isOpen">
                    <img src="../../assets/images/dataCenter/open-bg.png" alt="" />
                  </div>
                  <div v-else>
                    <img src="../../assets/images/dataCenter/close-bg.png" alt="" />
                  </div>
                </div>
                <div class="value-state-box">
                  阀门:
                  <span v-if="isOpen" class="value-state-close">开</span>
                  <span v-else class="value-state-close" style="color: red">
                    关
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 异常处理-处理建议-模态框 -->
    <a-modal v-model="modal.show" title="异常处理" @ok="expHandleModalOK">
      <div style="height: 20px"></div>
      <a-textarea placeholder="请输入处理意见..." :rows="4" v-model="modal.message" />
      <div style="height: 20px"></div>
    </a-modal>
    <!--行为违规处理模态框-->
    <a-modal
      v-model="behaviorModal.visible"
      title="行为违规处理"
      @ok="handleOkBehavior"
      @cancel="handleCancelBehavior"
    >
      <a-row type="flex">
        <a-col :span="24">
          <div class="areaInput">
            <span class="required">添加图片:</span>
            <div class="photosmall">
              <a-upload
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                action="api/client-manage/file/upload"
                :before-upload="imgBeforeUpload"
                :headers="headers"
                @change="file => handleFileChange(file, 'facePic')"
              >
                <img v-if="behaviorModal.facePic" :src="behaviorModal.facePic" alt="avatar" />
                <div v-else>
                  <a-icon :type="behaviorModal.loadingFace ? 'loading' : 'plus'" class="iconBox" />
                  <p class="desc">添加图片</p>
                </div>
              </a-upload>
            </div>
          </div>
          <div class="areaInput">
            <span class="required">处理意见:</span>
            <a-textarea placeholder="请输入" :rows="4" v-model="behaviorModal.remark" />
          </div>
        </a-col>
      </a-row>
    </a-modal>

    <!--健康证处理模态框-->
    <a-modal
      v-model="healthModal.visible"
      title="健康证预警处理"
      @ok="handleOkHealth"
      @cancel="handleCancelHealth"
    >
      <a-row type="flex">
        <a-col :span="24">
          <div class="areaInput">
            <span>上传新的健康证:</span>
            <div class="photosmall">
              <a-upload
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                action="api/client-manage/file/upload"
                :before-upload="imgBeforeUpload"
                :headers="headers"
                @change="file => handleHealthFileChange(file, 'facePic')"
              >
                <img v-if="healthModal.facePic" :src="healthModal.facePic" alt="avatar" />
                <div v-else>
                  <a-icon :type="healthModal.loadingFace ? 'loading' : 'plus'" class="iconBox" />
                  <p class="desc">添加图片</p>
                </div>
              </a-upload>
            </div>
          </div>
          <div class="areaInput">
            <span>新的有效期:</span>
            <a-date-picker
              type="DatePicker"
              :disabled-date="disabledDate"
              placeholder="请选择日期"
              v-model="healthModal.healthCardEffectiveDate"
            />
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import bigscreenService from '../../mock/services/bigscreen'
import behaviorService from '../../mock/services/behavior'
import staffService from '../../mock/services/staff'
import dataCenterService from '@/mock/services/datacenter'
import api from '../../mock/services/warnScreen'
import FsGauge from '@/components/FsGauge/FsGauge.vue'
import util from '@/assets/utils/utils'
import IntervalManager from '@/assets/utils/IntervalManager'
import ButtonChange from '../../components/ButtonChange/ButtonChange.vue'

export default {
  data() {
    return {
      colorStatus: 0,
      sendTime: false,
      intervalManager: null, // 定时器管理器
      logoUrl: '',
      timer: ' -年-月-日 ',
      week: '',
      canteenName: '', // 当前食堂名
      canteenId: -1, // 当前食堂ID
      entranceAlarmList: [], // 门禁预警
      otherExcepList: [], // 互留网+名厨亮灶
      sampleAlarmList: [], //  留样记录

      behaviorAlarm: [], // 行为id
      entranceAlarm: [], // 进入
      healthAlarmList: [], // 健康
      sampleOpenAlarm: [], // 留样
      unknownPersonAlarm: [], // 不明人员

      connectManager: {
        connector: {}, // 连接实体
        lockReconnect: false, // 重连接锁，当为true时，阻止重新连接。
        websocketUrl: '' // 本次连接的地址
      },
      echartDataList: {
        device_1: {
          deviceName: '配餐间',
          isOnline: false,
          echartData_1: {
            id: 'device_1_echart_1', // 图id--用于标签id
            isAlarm: false, // 是否告警
            curValue: 0, // 当前值
            maxValue: 50, // 图标最大值 --图标还没占位
            type: '温度' //  温度还是湿度
          },
          echartData_2: {
            id: 'device_1_echart_2', // 图id--用于标签id
            isAlarm: false, // 是否告警
            curValue: 0, // 当前值
            maxValue: 100, // 图标最大值 --图标还没占位
            type: '湿度' //  温度还是湿度
          }
        },
        device_2: {
          deviceName: '主食仓库',
          isOnline: false,
          echartData_1: {
            id: 'device_2_echart_1', // 图id--用于标签id
            isAlarm: false, // 是否告警
            curValue: 0, // 当前值
            maxValue: 50, // 图标最大值 --图标还没占位
            type: '温度' //  温度还是湿度
          },
          echartData_2: {
            id: 'device_2_echart_2', // 图id--用于标签id
            isAlarm: false, // 是否告警
            curValue: 0, // 当前值
            maxValue: 100, // 图标最大值 --图标还没占位
            type: '湿度' //  温度还是湿度
          }
        },
        device_3: {
          deviceName: '调味品仓库',
          isOnline: false,
          echartData_1: {
            id: 'device_3_echart_1', // 图id--用于标签id
            isAlarm: false, // 是否告警
            curValue: 0, // 当前值
            maxValue: 50, // 图标最大值 --图标还没占位
            type: '温度' //  温度还是湿度
          },
          echartData_2: {
            id: 'device_3_echart_2', // 图id--用于标签id
            isAlarm: false, // 是否告警
            curValue: 0, // 当前值
            maxValue: 100, // 图标最大值 --图标还没占位
            type: '湿度' //  温度还是湿度
          }
        },
        device_4: {
          deviceName: '可燃气体',
          isOnline: false,
          echartData_1: {
            id: 'device_4_echart_1', // 图id--用于标签id
            isAlarm: false, // 是否告警
            curValue: 0, // 当前值
            maxValue: 50, // 图标最大值 --图标还没占位
            type: '浓度' //  单位类型：温度还是湿度
          }
        }
      },
      // 基本填写意见模态框
      modal: {
        show: false, // 是否显示模态框
        curRecord: '', // 当前操作的记录index
        message: '' // 当前处理意见
      },
      // 行为违规处理模态框
      behaviorModal: {
        loadingFace: false,
        curRecord: '',
        visible: false,
        remark: '',
        modalPic: '',
        facePic: ''
      },
      // 健康证处理模态框
      healthModal: {
        loadingFace: false,
        curRecord: '',
        visible: false,
        healthcardPic: '',
        facePic: '',
        healthCardEffectiveDate: ''
      },
      headers: {
        Authorization: 'Bearer' + ' ' + sessionStorage.getItem('token')
      },
      //阀门
      isOpen: true
    }
  },
  activated() {
    // 进入页面-开启所有定时任务
    this.intervalManager.startAllTask()
  },
  destroyed() {
    this.intervalManager.stopAllTask()
  },
  mounted() {
    if (!this.colorStatus) {
      document.getElementById('app').className = 'themea'
    } else {
      document.getElementById('app').className = 'themeb'
    }
    // 获取路劲参数
    this.canteenName = this.$route.query.canteenName
    this.canteenId = this.$route.query.canteenId

    let thisPage = this
    this.intervalManager = new IntervalManager('WarnScreen定时任务管理器')

    // 初始化页面--获取所有的预警消息
    this.getTodayWarningInfoByCanteenId()

    // 刷新温湿度设备消息
    this.refreshTemperatureAndHumidityRecord()

    // 物联网瓦斯数据
    this.refreshGasData()

    // 建立实时预警消息长连接
    this.establishConnetion()

    // 获取食堂对应logo图标
    this.getSchoolLogo()

    // 时间显示定时任务
    this.intervalManager.addTask(
      function() {
        let timer = window.moment().format('YYYY-MM-DD HH:mm:ss d')
        let weekNum = Number(timer.charAt(timer.length - 1))
        let weekString = ''
        switch (weekNum) {
          case 0:
            weekString = '星期日'
            break
          case 1:
            weekString = '星期一'
            break
          case 2:
            weekString = '星期二'
            break
          case 3:
            weekString = '星期三'
            break
          case 4:
            weekString = '星期四'
            break
          case 5:
            weekString = '星期五'
            break
          case 6:
            weekString = '星期六'
            break
        }
        thisPage.timer = timer.substring(0, 20)
        thisPage.week = weekString
      },
      1000,
      '时钟任务'
    )
    // 气体数据刷新任务
    this.intervalManager.addTask(this.refreshGasData, 600000, '气体数据刷新任务')

    setTimeout(() => {
      let taskIndex = thisPage.intervalManager.addTask(thisPage.isConnect, 120000, '长连接心跳任务')
      thisPage.intervalManager.startTaskByIndex(taskIndex)
    }, 10000)
  },
  components: {
    FsGauge,
    ButtonChange
  },

  methods: {
    receiveValue(e) {
      this.colorStatus = e
    },

    //logo
    getSchoolLogo() {
      let thisPage = this
      dataCenterService.getCanteenPic(thisPage.canteenId).then(res => {
        thisPage.logoUrl = res.results.logoUrl
      })
    },

    // 获取今日累计的预警消息
    getTodayWarningInfoByCanteenId() {
      let thisPage = this
      api.getTodayWarningInfoByCanteenId(this.canteenId).then(res => {
        if (res && res.success) {
          let data = res.results
          data.forEach(element => {
            thisPage.distributeMessage(element.websocketVo.messageType, element.websocketVo.data)
          })
        }
      })
    },

    // 刷新温湿度设备信息。
    refreshTemperatureAndHumidityRecord() {
      let thisPage = this
      api.getTemperatureAndHumidityRecord(this.canteenId).then(res => {
        if (res && res.success && res.results) {
          res.results.forEach(v => {
            thisPage.distributeMessage(v.websocketVo.messageType, v.websocketVo.data)
          })
        }
      })
    },

    // 可燃性气体（瓦斯）数据
    refreshGasData() {
      let thisPage = this
      api
        .getGasDeviceList({
          canteenId: thisPage.canteenId
        })
        .then(res => {
          if (res && res.success && res.results && res.results.length) {
            thisPage.echartDataList.device_4.isOnline = res.results[0].deviceStatus
            thisPage.echartDataList.device_4.echartData_1.curValue = res.results[0].currentGas
              ? Number(res.results[0].currentGas)
              : 0
            thisPage.echartDataList.device_4.echartData_1.maxValue = res.results[0].maxGas
              ? res.results[0].maxGas
              : 50
            thisPage.echartDataList.device_4.echartData_1 = {
              id: thisPage.echartDataList.device_4.echartData_1.id,
              isAlarm: Number(res.results[0].currentGas) ? true : false,
              curValue: thisPage.echartDataList.device_4.echartData_1.curValue,
              maxValue: thisPage.echartDataList.device_4.echartData_1.maxValue,
              type: thisPage.echartDataList.device_4.echartData_1.type
            }
          }
        })
    },

    /**
     * 健康证处理辅助函数
     */
    disabledDate(current) {
      return current && current < window.moment().endOf('day')
    },

    /**
     * 通信连接处理组
     */
    // 建立连接
    establishConnetion() {
      if (!('WebSocket' in window)) {
        this.$alert.error('您的浏览器不支持WebSocket!这将无法接收到预警信息')
        return
      }
      if (this.connectManager.lockReconnect) {
        return
      }
      this.connectManager.lockReconnect = true
      let thisPage = this
      bigscreenService.getWebSocketApi(thisPage.canteenId).then(res => {
        if (res && res.success) {
          thisPage.connectManager.websocketUrl = thisPage.$socketfix + res.results
          thisPage.connectManager.connector = new WebSocket(thisPage.connectManager.websocketUrl)
          thisPage.connectManager.connector.onopen = function() {
            console.log('尝试打开连接...', window.moment().format('YYYY-MM-DD HH:mm:ss'))
          }
          thisPage.connectManager.connector.onmessage = function(e) {
            thisPage.sendTime = true
            let result = JSON.parse(e.data)
            thisPage.distributeMessage(result.messageType, result.data)
          }
          thisPage.connectManager.connector.onclose = function() {
            console.log('WebSocket连接断开:', window.moment().format('YYYY-MM-DD HH:mm:ss'))
            thisPage.connectManager.lockReconnect = false
            thisPage.establishConnetion()
          }
        }
      })
    },
    isConnect() {
      let thisPage = this
      thisPage.sendTime = false
      thisPage.connectManager.connector &&
        thisPage.connectManager.connector.readyState == 1 &&
        thisPage.connectManager.connector.send('心跳检测')
      setTimeout(function() {
        if (!thisPage.sendTime) {
          console.log('断网重启', window.moment().format('YYYY-MM-DD HH:mm:ss'))
          thisPage.connectManager.lockReconnect = false
          thisPage.establishConnetion()
        }
      }, 6000)
    },
    distributeMessage(messageType, data) {
      switch (messageType) {
        // 消息显示类数据
        case 'M_ALARM_ENTRANCE': // 门禁预警消息推送
          this.entranceAlarmList.unshift(data)
          break
        case 'M_ALARM_HEALTH': // 健康整预警
          this.entranceAlarmList.unshift(data)
          break
        case 'M_ALARM_UNKNOWNPERSON': // 不明人员消息推送（图片在自己服务器）
          data.pic = this.$imgPrefix + data.pic
          this.otherExcepList.unshift(data)
          break
        case 'M_ALARM_BEHAVIOR': // 行为违规消息推送(图片在外服务器)
          this.otherExcepList.unshift(data)
          break
        case 'M_ALARM_SAMPLE': // 留样预警消息推送
          this.sampleAlarmList.unshift(data)
          break
        case 'M_ALARM_SAMPLE_OPEN_RECORD': // 留样柜开门记录消息推送
          data.pic = this.$imgPrefix + data.pic
          this.otherExcepList.unshift(data)
          break
        case 'M_ALARM_TEMPERATURE_HUMIDITY': // 实时温湿度预警消息推送
          this.distributeTemperatureAndHumidity(data)
          break
        case 'M_ALARM_GAS_SWITCH': //阀门
          if (data.isOpen === 'false') {
            this.isOpen = false
          } else {
            this.isOpen = true
          }
          console.log(this.isOpen)
          console.log(data)
          break

        // 数据处理类交互
        case 'M_ALARM_ENTRANCE_HANDLE': // 门禁预警消息处理
          this.distributeExpHandleResponse(messageType, data)
          break
        case 'M_ALARM_HEALTH_HANDLE': // 健康证消息处理
          this.distributeExpHandleResponse(messageType, data)
          break
        case 'M_ALARM_UNKNOWNPERSON_HANDLE': // 不明人员消息处理
          this.distributeExpHandleResponse(messageType, data)
          break
        case 'M_ALARM_BEHAVIOR_HANDLE': // 行为违规消息处理
          this.distributeExpHandleResponse(messageType, data)
          break
        case 'M_ALARM_SAMPLE_HANDLE': // 留样预警消息处理
          this.distributeExpHandleResponse(messageType, data)
          break
        case 'M_ALARM_SAMPLE_OPEN_RECORD_HANDLE': // 留样柜开门记录消息处理
          this.distributeExpHandleResponse(messageType, data)
          break
      }
    },
    //温湿度消息分发。
    distributeTemperatureAndHumidity(data) {
      switch (data.type) {
        case '配餐间':
          this.echartDataList.device_1.isOnline = data.data.status == 0
          this.echartDataList.device_1.echartData_1.curValue = data.data.temperature
          this.echartDataList.device_1.echartData_2.curValue = data.data.humidity
          if (data.data.temperature >= 25) {
            this.echartDataList.device_1.echartData_1.isAlarm = true
          } else {
            this.echartDataList.device_1.echartData_1.isAlarm = false
          }
          if (data.data.humidity >= 80) {
            this.echartDataList.device_1.echartData_2.isAlarm = true
          } else {
            this.echartDataList.device_1.echartData_2.isAlarm = false
          }
          this.echartDataList.device_1.echartData_1 = {
            id: this.echartDataList.device_1.echartData_1.id,
            isAlarm: this.echartDataList.device_1.echartData_1.isAlarm,
            curValue: this.echartDataList.device_1.echartData_1.curValue,
            maxValue: this.echartDataList.device_1.echartData_1.maxValue,
            type: this.echartDataList.device_1.echartData_1.type
          }
          this.echartDataList.device_1.echartData_2 = {
            id: this.echartDataList.device_1.echartData_2.id,
            isAlarm: this.echartDataList.device_1.echartData_2.isAlarm,
            curValue: this.echartDataList.device_1.echartData_2.curValue,
            maxValue: this.echartDataList.device_1.echartData_2.maxValue,
            type: this.echartDataList.device_1.echartData_2.type
          }

          break
        case '主食仓库':
          this.echartDataList.device_2.isOnline = data.data.status == 0
          this.echartDataList.device_2.echartData_1.curValue = data.data.temperature
          this.echartDataList.device_2.echartData_2.curValue = data.data.humidity
          if (data.data.temperature >= 25) {
            this.echartDataList.device_2.echartData_1.isAlarm = true
          } else {
            this.echartDataList.device_2.echartData_1.isAlarm = false
          }
          if (data.data.humidity >= 80) {
            this.echartDataList.device_2.echartData_2.isAlarm = true
          } else {
            this.echartDataList.device_2.echartData_2.isAlarm = false
          }
          this.echartDataList.device_2.echartData_1 = {
            id: this.echartDataList.device_2.echartData_1.id,
            isAlarm: this.echartDataList.device_2.echartData_1.isAlarm,
            curValue: this.echartDataList.device_2.echartData_1.curValue,
            maxValue: this.echartDataList.device_2.echartData_1.maxValue,
            type: this.echartDataList.device_2.echartData_1.type
          }
          this.echartDataList.device_2.echartData_2 = {
            id: this.echartDataList.device_2.echartData_2.id,
            isAlarm: this.echartDataList.device_2.echartData_2.isAlarm,
            curValue: this.echartDataList.device_2.echartData_2.curValue,
            maxValue: this.echartDataList.device_2.echartData_2.maxValue,
            type: this.echartDataList.device_2.echartData_2.type
          }
          break
        case '调味品仓库':
          this.echartDataList.device_3.isOnline = data.data.status == 0
          this.echartDataList.device_3.echartData_1.curValue = data.data.temperature
          this.echartDataList.device_3.echartData_2.curValue = data.data.humidity
          if (data.data.temperature >= 25) {
            this.echartDataList.device_3.echartData_1.isAlarm = true
          } else {
            this.echartDataList.device_3.echartData_1.isAlarm = false
          }
          if (data.data.humidity >= 80) {
            this.echartDataList.device_3.echartData_2.isAlarm = true
          } else {
            this.echartDataList.device_3.echartData_2.isAlarm = false
          }
          this.echartDataList.device_3.echartData_1 = {
            id: this.echartDataList.device_3.echartData_1.id,
            isAlarm: this.echartDataList.device_3.echartData_1.isAlarm,
            curValue: this.echartDataList.device_3.echartData_1.curValue,
            maxValue: this.echartDataList.device_3.echartData_1.maxValue,
            type: this.echartDataList.device_3.echartData_1.type
          }
          this.echartDataList.device_3.echartData_2 = {
            id: this.echartDataList.device_3.echartData_2.id,
            isAlarm: this.echartDataList.device_3.echartData_2.isAlarm,
            curValue: this.echartDataList.device_3.echartData_2.curValue,
            maxValue: this.echartDataList.device_3.echartData_2.maxValue,
            type: this.echartDataList.device_3.echartData_2.type
          }
          break
      }
    },
    distributeExpHandleResponse(messageType, data) {
      if (data && data.type && typeof data.type === 'string') {
        data.type = data.type.substring(0, data.type.length - 7)
      }
      if (messageType === 'M_ALARM_ENTRANCE_HANDLE' || messageType === 'M_ALARM_HEALTH_HANDLE') {
        this.entranceAlarmList = this.entranceAlarmList.filter(item => {
          if (item.id != data.id || item.type != data.type) {
            return true
          }
          return false
        })
      } else if (
        messageType === 'M_ALARM_UNKNOWNPERSON_HANDLE' ||
        messageType === 'M_ALARM_BEHAVIOR_HANDLE' ||
        messageType === 'M_ALARM_SAMPLE_OPEN_RECORD_HANDLE'
      ) {
        this.otherExcepList = this.otherExcepList.filter(item => {
          if (item.id != data.id || item.type != data.type) {
            return true
          }
          return false
        })
      } else if (messageType === 'M_ALARM_SAMPLE_HANDLE') {
        this.sampleAlarmList = this.sampleAlarmList.filter(item => {
          if (item.id != data.id || item.type != data.type) {
            return true
          }
          return false
        })
      }
    },
    // 只填写处理意见的异常处理
    distributeExpHandleRequest(record, message) {
      switch (record.type) {
        case 'M_ALARM_ENTRANCE': // 门禁异常处理
          this.dealAlarmEntrance(record, message)
          break
        case 'M_ALARM_UNKNOWNPERSON': // 不明人员异常处理
          this.dealWithPersonById(record, message)
          break
        case 'M_ALARM_SAMPLE_OPEN_RECORD': // 应急开门
          this.handleSampleOpenRecord(record, message)
          break
      }
    },
    //分类打开处理模态框
    openModal(curListName, index) {
      //现分类处理异常
      switch (this[curListName][index].type) {
        case 'M_ALARM_ENTRANCE': // 门禁异常处理--只填写意见模态框
          this.modal.show = true
          this.modal.curRecord = this[curListName][index]
          break
        case 'M_ALARM_UNKNOWNPERSON': // 不明人员异常处理--只填写意见模态框
          this.modal.show = true
          this.modal.curRecord = this[curListName][index]
          break
        case 'M_ALARM_SAMPLE': //留样通知处理
          break
        case 'M_ALARM_HEALTH': // 健康证预警
          this.healthModal.curRecord = this[curListName][index]
          this.healthModal.visible = true
          break
        case 'M_ALARM_BEHAVIOR': // 行为违规--行为违规模态框
          this.behaviorModal.curRecord = this[curListName][index]
          this.behaviorModal.visible = true
          break
        case 2: // 延时留样
          break
        case 3: //超时未留样
          break
        case 'M_ALARM_SAMPLE_OPEN_RECORD': // 应急开门--只填写意见模态框
          this.modal.show = true
          this.modal.curRecord = this[curListName][index]
          break
      }
    },
    closeModal() {
      this.modal.show = false
      this.modal.message = ''
      this.modal.curRecord = ''
    },
    expHandleModalOK() {
      this.distributeExpHandleRequest(this.modal.curRecord, this.modal.message)
      this.closeModal()
    },
    handleOkBehavior() {
      if (!this.behaviorModal.remark) {
        this.$info({ title: '处理意见不能为空!' })
        return
      }
      if (!this.behaviorModal.modalPic) {
        this.$info({ title: '处理图片不能为空!' })
        return
      }
      this.dealWithStandardById(
        this.behaviorModal.curRecord.id,
        this.behaviorModal.remark,
        this.behaviorModal.modalPic
      )
      this.handleCancelBehavior()
    },
    handleCancelBehavior() {
      this.behaviorModal.visible = false
      this.behaviorModal.remark = ''
      this.behaviorModal.modalPic = ''
    },
    handleFileChange(info, attr) {
      if (info.file.status === 'uploading') {
        if (attr === 'facePic') this.behaviorModal.loadingFace = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        util.getBase64(info.file.originFileObj, imageUrl => {
          this.behaviorModal.modalPic = info.file.response.results
          this.behaviorModal[attr] = imageUrl
          this.behaviorModal.loadingFace = false
        })
      }
    },
    handleOkHealth() {
      if (!this.healthModal.healthCardEffectiveDate) {
        this.$info({ title: '有效期不能为空!' })
        return
      }
      if (!this.healthModal.healthcardPic) {
        this.$info({ title: '健康证图片不能为空!' })
        return
      }
      let thisPage = this
      let loginer = JSON.parse(sessionStorage.getItem('USERINFO'))
      this.handleHealthWarning({
        handleStatus: true,
        id: thisPage.healthModal.curRecord.id,
        healthcardPic: thisPage.healthModal.healthcardPic,
        healthCardEffectiveDate: thisPage.healthModal.healthCardEffectiveDate.format('YYYY-MM-DD'),
        canteenId: thisPage.curCanteenId,
        userId: loginer.id
      })
      this.handleCancelHealth()
    },
    handleCancelHealth() {
      this.healthModal.visible = false
      this.healthModal.healthCardEffectiveDate = ''
      this.healthModal.healthcardPic = ''
      this.healthModal.facePic = ''
    },
    handleHealthFileChange(info, attr) {
      if (info.file.status === 'uploading') {
        if (attr === 'facePic') this.healthModal.loadingFace = true
        return
      }
      if (info.file.status === 'done') {
        util.getBase64(info.file.originFileObj, imageUrl => {
          this.healthModal.healthcardPic = info.file.response.results
          this.healthModal[attr] = imageUrl
          this.healthModal.loadingFace = false
        })
      }
    },
    // 处理考勤异常
    dealAlarmEntrance(record, message) {
      let loginer = JSON.parse(sessionStorage.getItem('USERINFO'))
      bigscreenService
        .dealAlarmEntrance({
          handleMessage: message,
          handleUserId: loginer.id,
          handleUsername: loginer.username,
          id: record.id
        })
        .then(res => {
          console.log(res)
        })
    },
    // 处理不明人员
    dealWithPersonById(record, message) {
      let thisPage = this
      behaviorService
        .dealWithPersonById({
          id: record.id,
          reason: message,
          canteenId: thisPage.canteenId
        })
        .then(res => {
          console.log(res)
        })
    },
    // 处理行为违规
    dealWithStandardById(recordId, message, picURL) {
      let thisPage = this
      behaviorService
        .dealWithStandardById({
          id: recordId,
          reason: message,
          handlePicture: picURL
        })
        .then(res => {
          if (res && res.success) {
            thisPage.$info({ title: '处理成功！' })
          } else {
            thisPage.$info({ title: '处理失败！' })
          }
        })
    },
    // 留样应急开门处理
    handleSampleOpenRecord(record, message) {
      let thisPage = this
      let loginer = JSON.parse(sessionStorage.getItem('USERINFO'))
      bigscreenService
        .handleSampleOpenRecord({
          canteenId: thisPage.canteenId,
          handleMessage: message,
          handleUserId: loginer.id,
          handleUsername: loginer.username,
          id: record.id
        })
        .then(res => {
          console.log(res)
        })
    },
    // 处理健康证预警
    handleHealthWarning(params) {
      let thisPage = this
      staffService.updateHealthWarning(params).then(res => {
        if (res.success == true) {
          thisPage.$alert.info('处理成功！')
        } else {
          thisPage.$alert.info('处理失败！')
        }
      })
    },
    //颜色主题变化
    // colorBtnClick() {
    //   if (!this.colorStatus) {
    //     document.getElementById("app").className = "themeb";
    //     this.colorStatus = 1;
    //   } else {
    //     document.getElementById("app").className = "themec";
    //     this.colorStatus = 0;
    //   }
    // },
    // 一键处理
    handleBtn() {
      for (let i = 0; i < this.entranceAlarmList.length; i++) {
        this.entranceAlarm.push(this.entranceAlarmList[i].id)
      }
      for (let i = 0; i < this.sampleAlarmList.length; i++) {
        this.sampleOpenAlarm.push(this.sampleAlarmList[i].id)
      }
      for (let i = 0; i < this.sampleAlarmList.length; i++) {
        this.sampleOpenAlarm.push(this.sampleAlarmList[i].id)
      }
    }
  },
  filters: {
    typeFilter: function(value) {
      switch (value) {
        case 'M_ALARM_ENTRANCE':
          return '温度异常'
        case 'M_ALARM_UNKNOWNPERSON':
          return '不明人员'
        case 'M_ALARM_SAMPLE':
          return '即将留样通知'
        case 'M_ALARM_HEALTH':
          return '健康证预警'
        case 'M_ALARM_BEHAVIOR':
          return '行为违规'
        case 2:
          return '延时留样'
        case 3:
          return '超时未留样'
        case 'M_ALARM_SAMPLE_OPEN_RECORD':
          return '应急开门'
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../assets/css/common.less');
@import url('../../assets/style/color.less');
.flex {
  display: flex;
}
.spaceBetween {
  justify-content: space-between;
}
.main {
  @boxigreen: rgb(106, 195, 89);
  font-family: 'Source Han Sans CN_Medium';
  font-weight: 800;
  width: 100vw;
  height: 100vh;
  background: #f0f1f6;
  > .head {
    // border: 1px solid red;
    width: .px2vw (1876) [ @vw];
    margin: 0 auto;
    height: .px2vh (82) [ @vh];
    border-radius: .px2vh (10) [ @vh];
    line-height: .px2vh (82) [ @vh];
    // background-color: white;
    position: relative;
    margin-bottom: .px2vh (20) [ @vh];
    .page-title-left {
      // float: left;
      width: .px2vw (200) [ @vw];
      height: .px2vh (82) [ @vh];
      .page-title-left-icon {
        display: inline-block;
        // border: 1px solid red;
        margin-left: 16px;
        // width: 100%;
        height: .px2vh (82) [ @vh];
        line-height: .px2vh (82) [ @vh];
        overflow: hidden;
        // background-image: url("../../assets/images/login/logo.png");
        // background-repeat: no-repeat;
        // background-size: 100% auto;
      }
    }
    .page-title {
      // position: absolute;
      // top: 0;
      // margin: 0 auto;
      width: 100%;
      height: .px2vh (68) [ @vh];
      font-size: .px2vh (38) [ @vh];
      letter-spacing: .px2vw (10) [ @vw];
      text-align: center;
      // background-image: url("../../assets/images/warnScreen/title-bg.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
    }
    .page-title-right {
      align-items: center;
      // float: right;
      // width: .px2vw (580) [ @vw];
      height: .px2vh (82) [ @vh];
      // border: 1px solid red;
      padding-right: 20px;
      color: rgb(101, 101, 103);
      font-size: .px2vw (20) [ @vw];
      // .colorBtn {
      //   width: .px2vw (74) [ @vw];
      //   height: .px2vh (32) [ @vh];
      //   border-radius: .px2vh (16) [ @vh];
      //   // background: #9dff80;
      //   margin-right: .px2vw (23) [ @vw];
      // }
      // .page-title-handle {
      //   // border: 1px solid red;
      //   margin-right: 5px;
      //   width: .px2vw (128) [ @vw];
      //   height: .px2vh (82) [ @vh];
      //   background-image: url("../../assets/images/warnScreen/handle.png");
      //   background-repeat: no-repeat;
      //   background-size: 100%;
      // }
      .page-title-right-icon {
        // vertical-align: text-bottom;
        display: inline-block;
        width: .px2vw (22) [ @vw];
        height: .px2vh (82) [ @vh];
        background-image: url('../../assets/images/warnScreen/dot.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        // border: 1px solid red;
        // display: inline-block;
      }
      .page-title-right-text {
        height: .px2vh (82) [ @vh];
        // border: 1px solid red;
        margin-left: .px2vw (10) [ @vw];
        flex-shrink: 0;
        font-size: .px2vh (18) [ @vh];
        color: #a4a4a4;
        font-weight: 400;
        .date {
          width: .px2vw (210) [ @vw];
          margin-right: .px2vw (-20) [ @vw];
          overflow: hidden;
        }
      }
    }
  }
  > .body {
    width: 100%;
    height: calc(100vh - .px2vh (100) [ @vh]);
    display: flex;
    justify-content: space-around;
    padding: 0 .px2vw (10) [ @vw];
    .box {
      width: 32.5%;
      height: 100%;
      .head {
        // border: 1px solid red;
        background: rgba(0, 0, 0, 0);
        display: flex;
        align-items: center;
        margin-bottom: .px2vh (10) [ @vh];
        .title {
          font-size: .px2vh (26) [ @vh];
          font-weight: 400;
          margin-left: .px2vw (20) [ @vw];
        }
      }
    }
    .left-block {
      .content {
        height: .px2vh (912) [ @vh];
        background: white;
        border-radius: 10px;
        overflow: hidden;
        // box-shadow: 0 0 10px rgb(218, 218, 218);
      }
    }
    .center-block {
      .content {
        height: .px2vh (912) [ @vh];
        background: white;
        border-radius: 10px;
        overflow: hidden;
        // box-shadow: 0 0 10px rgb(218, 218, 218);
      }
    }
    .right-block {
      .content {
        height: .px2vh (912) [ @vh];
        display: flex;
        flex-direction: column;
        // overflow: hidden;
        .first-level {
          height: .px2vh (400) [ @vh];
          background: white;
          border-radius: 10px;
          overflow: hidden;
          // box-shadow: 0 0 10px rgb(218, 218, 218);
        }
        .bottom-box {
          height: .px2vh (500) [ @vh];
          border-radius: 20px;
          display: flex;
          justify-content: space-between;
          // justify-content: space-around;
          .column-box {
            width: 48%;
            height: 100%;
            // background: white;
            border-radius: 20px;
            // box-shadow: 0 0 10px rgb(218, 218, 218);
            display: flex;
            flex-direction: column;
            // margin-right: .px2vw (20) [ @vw];
            .aaron-box {
              display: flex;
              justify-content: space-between;
              padding: 10px 12px;
              font-size: .px2vw (18) [ @vw];
              color: grey;
              .isOnline {
              }
            }
            .echarts-box {
              flex: 1;
            }
            .valve-box {
              width: 90%;
              height: 30%;
              margin: 0 auto;
              // background: url("../../assets/images/dataCenter/open-bg.png")
              //   no-repeat;
              // background-position: center;
              // background-size: contain;
              div {
                // border: 1px solid red;
                margin: 0 auto;
                width: 40%;
                img {
                  margin: 0 auto;
                  width: 100%;
                }
              }
            }
            .value-state-box {
              display: flex;
              justify-content: center;
              margin: 20 / @vh auto;
              font-weight: 700;
              font-size: .px2vw (18) [ @vw];
              .value-state-close {
                font-weight: 700;
                color: @boxigreen;
              }
            }
            .light {
              border-radius: 50%;
              width: .px2vw (18) [ @vw];
              height: .px2vw (18) [ @vw];
              background: rgb(0, 204, 0);
              margin-right: 5px;
            }
            .warning {
              border-radius: 50%;
              width: .px2vw (18) [ @vw];
              height: .px2vw (18) [ @vw];
              background: red;
              // margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
.table {
  font-size: .px2vw (18) [ @vw];
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .table-head {
    // width: calc(100% - 16px);
    width: 100%;
    padding: 0 .px2vw (16) [ @vw];
  }
  .scroll-box {
    width: 100%;
    overflow-y: scroll;
    flex: 1;
    padding-left: 8px;
    .table-body {
      width: 100%;
      height: 100%;
      .row:nth-child(even) {
        // background: rgba(87, 178, 69, 0.15);
      }
    }
    &:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  .scroll-box::-webkit-scrollbar {
    width: 8px;
  }
  .scroll-box::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  .row {
    display: flex;
    width: 100%;
    border-radius: 8px;
    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
    }
    .column-first {
      flex: 1;
    }
    .column-second {
      width: 27%;
    }
    .column-third {
      width: 27%;
    }
    .column-fourth {
      width: 27%;
    }
  }
  .text-weight-300 {
    font-weight: 300;
  }
  .text-weight-500 {
    font-weight: 500;
  }
  .text-weight-700 {
    font-weight: 700;
  }
  .line {
    width: 100%;
    height: 1px;
    // background: rgb(226, 226, 226);
  }
  .border-bottom {
    border-bottom: solid 1px rgb(226, 226, 226);
  }
  .border-left {
    // border-left: solid 1px rgb(226, 226, 226);
  }
}

.icon-abc {
  width: .px2vw (30) [ @vw];
  height: .px2vh (30) [ @vh];
  background-image: url('../../assets/images/warnScreen/abc.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.icon-bca {
  width: .px2vw (30) [ @vw];
  height: .px2vh (30) [ @vh];
  background-image: url('../../assets/images/warnScreen/bca.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.icon-cab {
  width: .px2vw (30) [ @vw];
  height: .px2vh (30) [ @vh];
  background-image: url('../../assets/images/warnScreen/cab.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.icon-acb {
  width: .px2vw (30) [ @vw];
  height: .px2vh (30) [ @vh];
  background-image: url('../../assets/images/warnScreen/acb.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.ant-upload > img {
  width: .px2vw (160) [ @vw];
  height: .px2vh (200) [ @vh];
}
.ant-modal-body {
  .areaInput b {
    font-size: 20 / @vw;
    line-height: 38 / @vw;
  }
  .areaInput span {
    &.required:before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}
._img {
  width: 80 / @vw;
  height: 80 / @vw;
}
.photosmall img {
  width: 200 / @vw;
  height: 200 / @vw;
}
</style>
