<template>
  <div :id="echartData.id" class="echart"></div>
</template>
<script>
/**echats引入组*/
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/gauge')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
export default {
  props: {
    echartData: {
      type: Object
    }
  },
  mounted() {
    this.drawLine()
    let thisPage = this
    // 这是一种比较重的重绘方法，可以解决图表重绘的文字大小问题
    window.addEventListener('resize', function() {
      thisPage.redrawEcharts()
    })
  },
  watch: {
    echartData() {
      this.redrawEcharts()
    }
  },
  computed: {},
  data() {
    return {
      myChart: ''
    }
  },
  methods: {
    drawLine() {
      let thisPage = this
      let myChart = echarts.init(document.getElementById(thisPage.echartData.id))
      this.myChart = myChart
      let option = {
        grid: {
          height: 200,
          left: 0,
          top: 0,
          bottom: 0,
          right: 0
        },
        series: [
          {
            name: 'gauge',
            type: 'gauge',
            axisLine: {
              lineStyle: {
                color: [
                  [
                    thisPage.echartData.curValue / thisPage.echartData.maxValue,
                    thisPage.echartData.isAlarm ? 'red' : '#5DD1FA'
                  ],
                  [1, '#f7f9fc']
                ],
                width: 8
              }
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            itemStyle: {
              show: false
            },
            title: {
              color: thisPage.echartData.isAlarm ? 'red' : 'green',
              fontSize: thisPage.fontSize(20),
              fontWeight: 'bolder'
            },
            detail: {
              offsetCenter: [0, '-10%'],
              textStyle: {
                fontSize: thisPage.fontSize(25),
                fontWeight: 'bolder',
                color: '#70AAE3'
              }
            },
            pointer: {
              show: false
            },
            data: [
              {
                name: thisPage.echartData.isAlarm ? '告警' : '正常',
                value: thisPage.echartData.curValue
              }
            ]
          },
          {
            title: {
              color: '#7f7f7f',
              fontSize: thisPage.fontSize(20),
              offsetCenter: [0, '100%']
            },
            type: 'gauge',
            splitNumber: 0,
            startAngle: 0,
            endAngle: 0,
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                name: thisPage.echartData.type
              }
            ]
          }
        ]
      }
      myChart.setOption(option)
      // window.addEventListener('resize',function () {
      //   这是一种重绘图表的放大，但是不会重绘图中的字体大
      //   if(myChart){
      //     myChart.resize();
      //   }
      // })
    },
    fontSize(value) {
      let clientWidth =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      if (!clientWidth) {
        return (20 * clientWidth) / 1920
      }
      return (value * clientWidth) / 1920
    },
    redrawEcharts() {
      let thisPage = this
      let option = {
        grid: {
          height: 200,
          left: 0,
          top: 0,
          bottom: 0,
          right: 0
        },
        series: [
          {
            name: 'gauge',
            type: 'gauge',
            axisLine: {
              lineStyle: {
                color: [
                  [
                    thisPage.echartData.curValue / thisPage.echartData.maxValue,
                    thisPage.echartData.isAlarm ? 'red' : '#5DD1FA'
                  ],
                  [1, '#f7f9fc']
                ],
                width: 8
              }
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            itemStyle: {
              show: false
            },
            title: {
              color: thisPage.echartData.isAlarm ? 'red' : 'green',
              fontSize: thisPage.fontSize(20),
              fontWeight: 'bolder'
            },
            detail: {
              offsetCenter: [0, '-10%'],
              textStyle: {
                fontSize: thisPage.fontSize(25),
                fontWeight: 'bolder',
                color: '#70AAE3'
              }
            },
            pointer: {
              show: false
            },
            data: [
              {
                name: thisPage.echartData.isAlarm ? '告警' : '正常',
                value: thisPage.echartData.curValue
              }
            ]
          },
          {
            title: {
              color: '#7f7f7f',
              fontSize: thisPage.fontSize(20),
              offsetCenter: [0, '100%']
            },
            type: 'gauge',
            splitNumber: 0,
            startAngle: 0,
            endAngle: 0,
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                name: thisPage.echartData.type
              }
            ]
          }
        ]
      }
      this.myChart.setOption(option)
      this.myChart.resize()
    }
  }
}
</script>
<style lang="less" scoped>
* {
  pointer-events: all;
  cursor: pointer;
}
.echart {
  width: 100%;
  height: 50%;
}
// 苏大 东校区食堂 独墅湖五食堂
</style>
