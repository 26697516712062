import axios from "./http-interceptoer";
export default {
  /**
   * 获取今日门禁刷卡次数
   * @param {*} params
   * @returns
   */
  getTodayEntranceList(params) {
    return axios.get("/api/client-manage/clientEntrance/list/today/all", {
      params
    });
  },

  // 查询不明人员列表
  getUnknownPersonList(params) {
    return axios.get("/api/client-manage/unknownPerson/list/page", { params });
  },
  /**
   * 大屏查询不明人员列表
   * @param {*} canteenId | 食堂id
   * @param {*} count | 数量
   * @returns
   */
  getBigUnknownPerson(canteenId, count) {
    return axios.get(
      "/api/client-manage/screen/listUnknownPerson/" +
        canteenId +
        "?count=" +
        count
    );
  },
  // 查询今日不明人数
  listTodayUnknownPersonNumInfo(canteenId) {
    return axios.get(
      "/api/client-manage/screen/listTodayUnknownPersonNumInfo/" + canteenId
    );
  },
  // 根据不明人员ID更新信息
  updateUnknownPerson(params) {
    return axios.put("/api/client-manage/unknownPerson/update", params);
  },
  // 根据处理不明人员
  dealWithPersonById(params) {
    return axios.put("/api/client-manage/unknownPerson/dealwith", params);
  },
  // 一键处理所有不明人员
  dealAllPerson(params) {
    return axios.put("/api/client-manage/unknownPerson/dealAll", params);
  },
  // 获取区域列表
  getAreaList(params) {
    return axios.get("/api/client-manage/behaviorArea/list/page", { params });
  },
  // 获取区域列表
  getAllAreaList(params) {
    return axios.get("/api/client-manage/behaviorArea/list", { params });
  },
  // 新增区域
  addArea(params) {
    return axios.post("/api/client-manage/behaviorArea/add", params);
  },
  // 根据ID删除区域
  deleteAreaById(id) {
    return axios.delete("/api/client-manage/behaviorArea/delete/" + id);
  },
  // 根据ID更新区域
  updateAreaById(params) {
    return axios.put("/api/client-manage/behaviorArea/update", params);
  },
  // 根据区域ID查询AI摄像头
  getAiCameraByAreaId(id) {
    return axios.get("/api/client-manage/behaviorArea/list/" + id);
  },

  // 新增AI摄像头
  addAiCamera(params) {
    return axios.post("/api/client-manage/aiCamera/add", params);
  },
  // 编辑AI摄像头
  updateAiCamera(params) {
    return axios.put("/api/client-manage/aiCamera/update", params);
  },
  // 获取AI摄像头
  getAiCameraList(params) {
    return axios.get("/api/client-manage/aiCamera/list/page", { params });
  },
  // 删除AI摄像头
  deleteAiCameraById(id) {
    return axios.delete("/api/client-manage/aiCamera/delete/" + id);
  },
  //新增违规行为
  addStandard(params) {
    return axios.post("/api/client-manage/behaviorStandard/add", params);
  },
  // 获取所有违规行为
  getStandardList(params) {
    return axios.get("/api/client-manage/behaviorStandard/list/page", {
      params
    });
  },
  // 编辑违规行为
  updateStandard(params) {
    return axios.put("/api/client-manage/behaviorStandard/update", params);
  },
  // 删除全部违规行为
  dealWithStandardByAll(params) {
    return axios.put(
      "/api/client-manage/behaviorStandard/dealwithAll" +
        "?reason=" +
        params.reason +
        "&canteenId=" +
        params.canteenId,
      params
    );
  },
  // 删除违规行为
  dealWithStandardById(params) {
    return axios.put(
      "/api/client-manage/behaviorStandard/dealwith/" +
        params.id +
        "?handlePicture=" +
        params.handlePicture +
        "&reason=" +
        params.reason,
      params
    );
  },
  //websockt Fn
  getWebSocketApi(canteenId) {
    return axios.get("/api/client-manage/web-socket/getToken/" + canteenId);
  }
};
