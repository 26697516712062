import axios from "./http-interceptoer.js";

export default {
  // 分页查询健康证预警信息
  getHealthWarning(params) {
    return axios.get("/api/client-manage/clientHealthRecordWarning/list/page", {
      params
    });
  },
  // 更新健康证预警信息
  updateHealthWarning(params) {
    return axios.put(
      "/api/client-manage/clientHealthRecordWarning/update",
      params
    );
  },
  //分页查询考勤管理信息
  getAttendance(params) {
    return axios.get("/api/client-manage/clientAttendance/list/page", {
      params
    });
  },
  //异常考勤
  getExceptionAttend(params) {
    return axios.get("/api/client-manage/clientAttendance/list/abnormal", {
      params
    });
  },

  // 删除考勤
  deleteAttendance(id) {
    return axios.delete("/api/client-manage/clientAttendance/delete/" + id);
  },

  //导出考勤
  exportClientAttendance(params) {
    return axios.get("/api/client-manage/clientAttendance/export", {
      params: params,
      responseType: "blob"
    });
  },
  //处理考勤异常
  commitExceptionSuggestion(params) {
    return axios.post(
      "/api/client-manage/clientAttendance/handle/abnormal",
      params
    );
  }
};
