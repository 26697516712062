import axios from "./http-interceptoer.js";

export default {
  /**
   * 预警消息批量处理
   * @param {*} params
   * @returns
   */
  handleWarning(params) {
    return axios.post("/api/client-manage/screen/handleAlarmBatch", params);
  },
  /**
   * 根据食堂Id，获取今日所有的预警消息
   * @param {*} canteenId
   * @returns
   */
  getTodayWarningInfoByCanteenId(canteenId) {
    return axios.get(
      "/api/client-manage/screen/getCanteenAlarmBatch/" + canteenId
    );
  },
  /**
   * 温湿度设备信息
   * @param {*} canteenId
   * @returns
   */
  getTemperatureAndHumidityRecord(canteenId) {
    return axios.get(
      "/api/client-manage/screen/getTemperatureAndHumidityRecord/" + canteenId
    );
  },
  /**
   * 瓦斯设备信息
   * @param {*} params
   * @returns
   */
  getGasDeviceList(params) {
    return axios.get("/api/client-manage/gasDevice/list", { params });
  }
};
